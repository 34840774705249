/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AppointmentUpdateRequest } from '../models/AppointmentUpdateRequest';
import type { SuccessAppointmentInitiateReschedulingResponse } from '../models/SuccessAppointmentInitiateReschedulingResponse';
import type { SuccessAppointmentResponse } from '../models/SuccessAppointmentResponse';
import type { SuccessCollectionAppointmentResponse } from '../models/SuccessCollectionAppointmentResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AppointmentControllerService {

    /**
     * Marks an appointment as rescheduling. Does not change any other attribute, including status.
     * @param appointmentId
     * @returns SuccessAppointmentInitiateReschedulingResponse OK
     * @throws ApiError
     */
    public static initiateRescheduling(
        appointmentId: string,
    ): CancelablePromise<SuccessAppointmentInitiateReschedulingResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/appointments/{appointmentId}/reschedule',
            path: {
                'appointmentId': appointmentId,
            },
            errors: {
                400: `Bad Request`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
                500: `An unexpected error has occurred.`,
            },
        });
    }

    /**
     * Updates the appointment. The only allowed operation at the moment is to modify its start and end time, or its status.
     * @param appointmentId
     * @param requestBody
     * @returns SuccessAppointmentResponse OK
     * @throws ApiError
     */
    public static updateMemberAppointment(
        appointmentId: string,
        requestBody: AppointmentUpdateRequest,
    ): CancelablePromise<SuccessAppointmentResponse> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v1/appointments/{appointmentId}',
            path: {
                'appointmentId': appointmentId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
                500: `An unexpected error has occurred.`,
            },
        });
    }

    /**
     * Returns latest appointment for type and current user.
     * @param type
     * @returns SuccessAppointmentResponse OK
     * @throws ApiError
     */
    public static getMostRecentAppointmentForType(
        type: 'PERSONAL' | 'ONBOARDING_SPECIALIST' | 'SYNC_VISIT' | 'COACHING_1ON1' | 'DIETITIAN_VISIT' | 'PHARMACIST_VISIT' | 'CCS_MED_CONSULT' | 'LAB_RESULTS_REVIEW' | 'SPECIALIST_VISIT' | 'AT_HOME_LABS_GETLABS',
    ): CancelablePromise<SuccessAppointmentResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/appointments/{type}/latest',
            path: {
                'type': type,
            },
            errors: {
                400: `Bad Request`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
                500: `An unexpected error has occurred.`,
            },
        });
    }

    /**
     * Returns an .ics file for the given appointment.
     * @param appointmentId
     * @param accept The content type to be expected by the client
     * @param authToken An optional short-lived authentication token
     * @returns string OK
     * @throws ApiError
     */
    public static getAppointmentIcsFile(
        appointmentId: string,
        accept?: any,
        authToken?: any,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/appointments/{appointmentId}.ics',
            path: {
                'appointmentId': appointmentId,
            },
            headers: {
                'Accept': accept,
            },
            query: {
                'authToken': authToken,
            },
            errors: {
                400: `Bad Request`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
                500: `An unexpected error has occurred.`,
            },
        });
    }

    /**
     * Returns all scheduled appointments for the current user.
     * @returns SuccessCollectionAppointmentResponse OK
     * @throws ApiError
     */
    public static getScheduledAppointments(): CancelablePromise<SuccessCollectionAppointmentResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/appointments/scheduled',
            errors: {
                400: `Bad Request`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
                500: `An unexpected error has occurred.`,
            },
        });
    }

}
