/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type DeviceResponse = {
    /**
     * Internal id for the device
     */
    deviceId: string;
    /**
     * Type of device
     */
    type: DeviceResponse.type;
    /**
     * Device model
     */
    model: string;
    /**
     * Optional additional information regarding the device
     */
    comment?: string;
    /**
     * Indicates whether the device is user-owned or issued by 9amHealth
     */
    provider: DeviceResponse.provider;
    /**
     * Indicates whether the device is connected by a cellular connection
     */
    connectedByCellular: boolean;
    /**
     * An optional id, issued by an external manufacturer, used for identifying the specific device
     */
    externalDeviceId?: string;
    /**
     * Indicates whether the device is used or no longer used
     */
    deviceStatus: DeviceResponse.deviceStatus;
    /**
     * If the device was automatically added through a prescription, the id will be filled
     */
    prescriptionId?: string;
};

export namespace DeviceResponse {

    /**
     * Type of device
     */
    export enum type {
        BLOOD_PRESSURE_MONITOR = 'BLOOD_PRESSURE_MONITOR',
        GLUCOMETER = 'GLUCOMETER',
        CGM = 'CGM',
        WEIGHT_SCALE = 'WEIGHT_SCALE',
        TAPE_MEASURE = 'TAPE_MEASURE',
        MOBILE_PHONE = 'MOBILE_PHONE',
    }

    /**
     * Indicates whether the device is user-owned or issued by 9amHealth
     */
    export enum provider {
        USER_OWNED = 'USER_OWNED',
        NINE_AM = 'NINE_AM',
    }

    /**
     * Indicates whether the device is used or no longer used
     */
    export enum deviceStatus {
        IN_USE = 'IN_USE',
        NOT_IN_USE = 'NOT_IN_USE',
    }


}

