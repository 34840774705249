/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ConsultationDataProviderSpecificsAdminResponse } from './ConsultationDataProviderSpecificsAdminResponse';
import type { ProviderDto } from './ProviderDto';
import type { UserSummaryResponse } from './UserSummaryResponse';

/**
 * Represents consultation data fetched from repository.
 */
export type ConsultationAdminResponse = {
    /**
     * Consultation's id.
     */
    id: string;
    /**
     * User's id.
     */
    userId: string;
    /**
     * Basic info about user who created consultation.
     */
    createdBy?: UserSummaryResponse;
    /**
     * Last update date.
     */
    lastUpdated: string;
    /**
     * Created date.
     */
    createdAt: string;
    /**
     * Provider assigned to consultation.
     */
    provider?: ProviderDto;
    /**
     * Care team user id assigned to consultation.
     */
    careTeamUserId?: string;
    /**
     * Consultation's provider.
     */
    consultationProviderType: ConsultationAdminResponse.consultationProviderType;
    /**
     * Provider-specific information about the consultation.
     */
    consultationProviderSpecifics: ConsultationDataProviderSpecificsAdminResponse;
    /**
     * Consultation's status.
     */
    status: ConsultationAdminResponse.status;
    /**
     * Consultation's summary.
     */
    reasonSummary?: string;
    /**
     * List of reasons.
     */
    reasons?: Array<'INITIAL_CONSULTATION' | 'FOLLOW_UP_CONSULTATION' | 'LABS_CONSULTATION' | 'ORDER_ONLY_CONSULTATION'>;
};

export namespace ConsultationAdminResponse {

    /**
     * Consultation's provider.
     */
    export enum consultationProviderType {
        WHEEL = 'WHEEL',
        TRUEPILL = 'TRUEPILL',
        ZENDESK = 'ZENDESK',
        NINE_AM_HEALTH = 'NINE_AM_HEALTH',
    }

    /**
     * Consultation's status.
     */
    export enum status {
        DRAFT = 'DRAFT',
        ACTIVE = 'ACTIVE',
        COMPLETED = 'COMPLETED',
        ISSUE = 'ISSUE',
        REJECTED = 'REJECTED',
        CANCELLED = 'CANCELLED',
        WAITING_FOR_CARE_TEAM = 'WAITING_FOR_CARE_TEAM',
    }


}

