/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PaymentMethod = {
    /**
     * The identifier (name) of the payment provider that is associated to this payment method.
     */
    provider: PaymentMethod.provider;
    /**
     * An identifier for the payment method itself, relative to the payment provider.
     */
    id: string;
    /**
     * The type of the payment method.
     */
    type: PaymentMethod.type;
    /**
     * The date when this payment method was registered.
     */
    createdAt: string;
    /**
     * A set of additional properties describing type-specific attributes of the payment method.
     */
    properties?: Record<string, any>;
    default?: boolean;
};

export namespace PaymentMethod {

    /**
     * The identifier (name) of the payment provider that is associated to this payment method.
     */
    export enum provider {
        STRIPE = 'STRIPE',
    }

    /**
     * The type of the payment method.
     */
    export enum type {
        CREDIT_CARD = 'CREDIT_CARD',
    }


}

