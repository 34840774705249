/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AdminAddDiagnosisRequest = {
    /**
     * patient id
     */
    userId?: string;
    /**
     * diagnosis start month
     */
    month?: AdminAddDiagnosisRequest.month;
    /**
     * diagnosis start year
     */
    year?: {
        value?: number;
        leap?: boolean;
    };
    /**
     * diagnosis code
     */
    diagnosisCode?: string;
    /**
     * diagnosis name
     */
    name?: string;
};

export namespace AdminAddDiagnosisRequest {

    /**
     * diagnosis start month
     */
    export enum month {
        JANUARY = 'JANUARY',
        FEBRUARY = 'FEBRUARY',
        MARCH = 'MARCH',
        APRIL = 'APRIL',
        MAY = 'MAY',
        JUNE = 'JUNE',
        JULY = 'JULY',
        AUGUST = 'AUGUST',
        SEPTEMBER = 'SEPTEMBER',
        OCTOBER = 'OCTOBER',
        NOVEMBER = 'NOVEMBER',
        DECEMBER = 'DECEMBER',
    }


}

