/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { GuarantorItem } from './GuarantorItem';
import type { PayerAddressObject } from './PayerAddressObject';

/**
 * Information that needs to be provided in order for a user to store/update a medical insurance details.
 * @deprecated
 */
export type MedicalInsuranceRequestOld = {
    /**
     * CardHolder ID of a Pharmacy Insurance or a Manufacturer Coupon.
     */
    cardHolderId: string;
    /**
     * Group Number of a Pharmacy Insurance or a Manufacturer Coupon.
     */
    rxGrp?: string;
    /**
     * Status of current Insurance/Coupon.
     */
    status: MedicalInsuranceRequestOld.status;
    /**
     * The date when a insurance is effective from.
     */
    effectiveStartDate?: string;
    /**
     * The date when this insurance is effective until.
     */
    effectiveEndDate?: string;
    /**
     * The ID of uploaded file which is front of the card.
     */
    frontPhotoFileId?: string;
    /**
     * The ID of uploaded file which is back of the card.
     */
    backPhotoFileId?: string;
    /**
     * The name of the insurance plan.
     */
    planName?: string;
    /**
     * Flag - if an insurance is a primary.
     */
    isPrimary: boolean;
    /**
     * Relationship to a cardholder.
     */
    primaryPlanOwner: MedicalInsuranceRequestOld.primaryPlanOwner;
    /**
     * The payer address.
     * @deprecated
     */
    payer?: PayerAddressObject;
    /**
     * The id of partner.
     */
    partnerId?: string;
    /**
     * Guarantor/Subscriber information, only present if the patient is a dependent
     */
    guarantor?: GuarantorItem;
    /**
     * Insurance base info
     */
    insurance: any;
};

export namespace MedicalInsuranceRequestOld {

    /**
     * Status of current Insurance/Coupon.
     */
    export enum status {
        ACTIVE = 'ACTIVE',
        INACTIVE = 'INACTIVE',
    }

    /**
     * Relationship to a cardholder.
     */
    export enum primaryPlanOwner {
        SELF = 'SELF',
        SPOUSE = 'SPOUSE',
        PARENT = 'PARENT',
        OTHER = 'OTHER',
        UNKNOWN = 'UNKNOWN',
    }


}

