/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type MedicationItem = {
    /**
     * Medication strength
     */
    strength?: string;
    /**
     * TruePill preferred medication ndc
     */
    truePillPreferredNdc?: string;
    /**
     * Medication ndc
     */
    ndc?: string;
    /**
     * Medication sku
     * @deprecated
     */
    sku?: string;
    /**
     * Seller name
     */
    seller?: MedicationItem.seller;
    /**
     * Indicates whether prescription options are available for this medication item
     */
    prescriptionOptionsAvailable?: boolean;
};

export namespace MedicationItem {

    /**
     * Seller name
     */
    export enum seller {
        NINE_AM_HEALTH = 'NINE_AM_HEALTH',
    }


}

