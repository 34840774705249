/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Eligibility result metadata
 */
export type EligibilityResponseItem = {
    performedAt?: string;
    partnerId?: string;
    status?: EligibilityResponseItem.status;
    ineligibilityReasons?: Array<'SSO_USER_INELIGIBLE' | 'ELIGIBILITY_NOT_STARTED' | 'ELIGIBILITY_NOT_STARTED_DUE_TO_HIRE_DATE' | 'ELIGIBILITY_TERMINATED' | 'INVALID_DATA' | 'NOT_FOUND'>;
    errorMessage?: string;
    /**
     * An optional lookup URL, when available (e.g. check performed via apero)
     */
    url?: string;
    partnerSpecificUserData?: Record<string, any>;
};

export namespace EligibilityResponseItem {

    export enum status {
        ELIGIBLE = 'ELIGIBLE',
        INELIGIBLE = 'INELIGIBLE',
        INFO_UNAVAILABLE = 'INFO_UNAVAILABLE',
        SKIPPED = 'SKIPPED',
        EXTERNAL_PROVIDER_ERROR = 'EXTERNAL_PROVIDER_ERROR',
    }


}

