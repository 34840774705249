/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Represents a shippable item, i.e. something that can be shipped for a given user.
 */
export type ShippableItemCandidateResponse = {
    /**
     * Identifies the subscription to which this item is associated.
     */
    subscriptionId: string;
    /**
     * Identifies the purchase item to which this item is associated.
     */
    purchaseItemId: string;
    /**
     * A human-readable name for this item.
     */
    displayName: string;
    /**
     * A SKU to identify this item within the shipment.
     */
    sku: string;
    /**
     * The quantity to be shipped.
     */
    quantity: number;
    /**
     * The type of the item to be shipped.
     */
    itemType: ShippableItemCandidateResponse.itemType;
    /**
     * The date when this item was last shipped to the user.
     */
    lastDelivered?: string;
    /**
     * The date when this item is regularly scheduled to be shipped next.
     */
    nextDelivery?: string;
};

export namespace ShippableItemCandidateResponse {

    /**
     * The type of the item to be shipped.
     */
    export enum itemType {
        MEDICATION = 'MEDICATION',
        LAB_TEST_KIT = 'LAB_TEST_KIT',
        TEST_STRIP = 'TEST_STRIP',
        GLUCOMETER = 'GLUCOMETER',
        LANCET = 'LANCET',
        COMPLEMENT = 'COMPLEMENT',
        WELCOME_KIT = 'WELCOME_KIT',
        BLOOD_PRESSURE_MONITOR = 'BLOOD_PRESSURE_MONITOR',
        CONNECTED_WEIGHT_SCALE = 'CONNECTED_WEIGHT_SCALE',
    }


}

