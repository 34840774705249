/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { MealPlanAdminResponse } from './MealPlanAdminResponse';

export type GetUserDetailsAdminResponse = {
    /**
     * Rx diet user id
     */
    rxDietUserId: string;
    /**
     * Username
     */
    username: string;
    /**
     * Weight
     */
    weight?: number;
    /**
     * Target weight
     */
    targetWeight?: number;
    /**
     * Workload
     */
    workload?: number;
    /**
     * Weekly sport sessions
     */
    weeklySportSessions?: number;
    /**
     * Stress lvl
     */
    stressLevel?: GetUserDetailsAdminResponse.stressLevel;
    /**
     * Daily sleep hours
     */
    dailySleepHours?: number;
    /**
     * Metabolism
     */
    metabolism?: GetUserDetailsAdminResponse.metabolism;
    /**
     * Allergens
     */
    allergens?: Array<'MILK' | 'EGGS' | 'PEANUTS' | 'NUTS' | 'CRUSTACEANS' | 'FISH' | 'SOY' | 'WHEAT'>;
    /**
     * Cooking skills
     */
    cookingSkills?: GetUserDetailsAdminResponse.cookingSkills;
    /**
     * Blender
     */
    blender?: boolean;
    /**
     * Diets
     */
    diets?: Array<'CANCER' | 'CKD' | 'DASH' | 'DEMENTIA' | 'DIABETES' | 'DIABETES_TYPE_1' | 'DIABETES_TYPE_2' | 'ESRD' | 'ESRD_HEMODIALYSIS' | 'ESRD_PERITONEAL_DIALYSIS' | 'FODMAP' | 'GLUTEN_FREE' | 'HEART_DISEASE' | 'HIGH_CHOLESTEROL' | 'HIGH_PROTEIN' | 'HYPERTENSION' | 'LOW_CARB' | 'MEDITERRANEAN' | 'OBESITY' | 'SOFT_FOOD' | 'STROKE' | 'VEGAN' | 'VEGETARIAN'>;
    /**
     * Forbidden ingredient groups
     */
    forbiddenIngredientGroups?: Array<string>;
    /**
     * Monthly food expenses
     */
    monthlyFoodExpenses?: number;
    /**
     * Weight loss target
     */
    weightTarget?: GetUserDetailsAdminResponse.weightTarget;
    /**
     * Main target
     */
    mainTarget?: GetUserDetailsAdminResponse.mainTarget;
    /**
     * Weight loss pace
     */
    weightChangePace?: number;
    /**
     * Generated meal plans
     */
    mealPlans?: Array<MealPlanAdminResponse>;
    /**
     * Premium quality groceries
     */
    premiumQualityGroceries?: boolean;
    /**
     * Additional household serving sizes
     */
    additionalHouseholdServingSizes?: Array<'S' | 'M' | 'L' | 'XL' | 'XXL'>;
};

export namespace GetUserDetailsAdminResponse {

    /**
     * Stress lvl
     */
    export enum stressLevel {
        LOW = 'LOW',
        MEDIUM = 'MEDIUM',
        HIGH = 'HIGH',
    }

    /**
     * Metabolism
     */
    export enum metabolism {
        SLOW = 'SLOW',
        AVERAGE = 'AVERAGE',
        FAST = 'FAST',
    }

    /**
     * Cooking skills
     */
    export enum cookingSkills {
        LOW = 'LOW',
        MEDIUM = 'MEDIUM',
        HIGH = 'HIGH',
    }

    /**
     * Weight loss target
     */
    export enum weightTarget {
        LOSE_WEIGHT = 'LOSE_WEIGHT',
        GAIN_WEIGHT = 'GAIN_WEIGHT',
        PRESERVE_WEIGHT = 'PRESERVE_WEIGHT',
    }

    /**
     * Main target
     */
    export enum mainTarget {
        HEALTH = 'HEALTH',
        INSPIRATION = 'INSPIRATION',
        SAVE_TIME = 'SAVE_TIME',
        SAVE_MONEY = 'SAVE_MONEY',
    }


}

