/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ShipmentItemResponse } from './ShipmentItemResponse';

/**
 * Represents the item of the shipment record list, contains all the data related to the given shipment
 */
export type ShipmentResponse = {
    /**
     * Id of the shipment
     */
    id: string;
    /**
     * Status of the shipment
     */
    status: ShipmentResponse.status;
    /**
     * Date of delivery
     */
    deliveredAt?: string;
    /**
     * Date of initiation
     */
    createdAt?: string;
    /**
     * Collection of the items for given shipment
     */
    items?: Array<ShipmentItemResponse>;
};

export namespace ShipmentResponse {

    /**
     * Status of the shipment
     */
    export enum status {
        PENDING = 'PENDING',
        INITIATED = 'INITIATED',
        REVIEWING = 'REVIEWING',
        TRANSIT = 'TRANSIT',
        DELIVERED = 'DELIVERED',
        RETURNED = 'RETURNED',
        ERROR = 'ERROR',
    }


}

