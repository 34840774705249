/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AppliedCouponResponse } from './AppliedCouponResponse';
import type { PaymentResponse } from './PaymentResponse';
import type { PurchaseItemResponse } from './PurchaseItemResponse';
import type { SubscriptionStatusHistoryItemResponse } from './SubscriptionStatusHistoryItemResponse';

/**
 * The response to a subscription details operation.
 */
export type SubscriptionDetailsResponse = {
    /**
     * The ID of the subscription.
     */
    id: string;
    /**
     * The ID of the subscription owner
     */
    userId: string;
    /**
     * The status of subscription. Possible values are : DRAFT,IN_REVIEW,ACTIVE,FINISHED
     */
    status: SubscriptionDetailsResponse.status;
    /**
     * The date of subscription start, can be null
     */
    startDate?: string;
    /**
     * The date of subscription end, can be null
     */
    validToDate?: string;
    /**
     * The date of subscription creation
     */
    createdDate: string;
    /**
     * The date of next cancellation
     */
    nextCancellationDate: string;
    /**
     * The date and thime when the subscription was actually canceled.
     */
    cancellationDate?: string;
    /**
     * The reason for cancellation.
     */
    cancellationReason?: SubscriptionDetailsResponse.cancellationReason;
    /**
     * Further information about the cancellation reason.
     */
    cancellationText?: string;
    /**
     * The interval that is used to calculate price and to deliver service
     */
    renewalInterval: string;
    /**
     * The payment interval (= time interval where user is charged)
     */
    paymentInterval: string;
    /**
     * The currency of subscription
     */
    currency: string;
    /**
     * The total price of subscription.
     */
    totalPrice: number;
    /**
     * The total discount of subscription.
     */
    totalDiscount: number;
    /**
     * The total discount of subscription provided by coupons.
     */
    totalCouponDiscount: number;
    /**
     * The name of discount policy applied
     */
    discountPolicy?: SubscriptionDetailsResponse.discountPolicy;
    /**
     * The ID of the plan (based on payer and employer) for this subscription.
     */
    planId: string;
    /**
     * The identifier of the funnel used to subscribe to this subscription.
     */
    funnel?: string;
    /**
     * The collection of subscription items
     */
    purchaseItems: Array<PurchaseItemResponse>;
    /**
     * The collection of subscription payments
     */
    payments: Array<PaymentResponse>;
    /**
     * A collection of applied coupons.
     */
    appliedCoupons: Array<AppliedCouponResponse>;
    /**
     * Subscription status history items.
     */
    statusHistoryItems?: Array<SubscriptionStatusHistoryItemResponse>;
};

export namespace SubscriptionDetailsResponse {

    /**
     * The status of subscription. Possible values are : DRAFT,IN_REVIEW,ACTIVE,FINISHED
     */
    export enum status {
        DRAFT = 'DRAFT',
        IN_REVIEW = 'IN_REVIEW',
        ACTIVE = 'ACTIVE',
        REJECTED = 'REJECTED',
        FINISHED = 'FINISHED',
        PAUSED = 'PAUSED',
    }

    /**
     * The reason for cancellation.
     */
    export enum cancellationReason {
        ONETIME_PURCHASE = 'ONETIME_PURCHASE',
        MEDICAL_INELIGIBILITY = 'MEDICAL_INELIGIBILITY',
        PAYER_INELIGIBILITY = 'PAYER_INELIGIBILITY',
        PAYMENT_ISSUE = 'PAYMENT_ISSUE',
        OOP_COSTS = 'OOP_COSTS',
        DISENGAGEMENT = 'DISENGAGEMENT',
        MEMBER_REQUEST = 'MEMBER_REQUEST',
        OTHER = 'OTHER',
    }

    /**
     * The name of discount policy applied
     */
    export enum discountPolicy {
        FIRST_MEDICATION_INCLUDED = 'FIRST_MEDICATION_INCLUDED',
        TWO_MEDICATIONS_INCLUDED = 'TWO_MEDICATIONS_INCLUDED',
        TWENTY_PERCENT_OFF = 'TWENTY_PERCENT_OFF',
        FIFTY_PERCENT_OFF = 'FIFTY_PERCENT_OFF',
        TWO_THIRDS_OFF = 'TWO_THIRDS_OFF',
        SEVENTYFIVE_PERCENT_OFF = 'SEVENTYFIVE_PERCENT_OFF',
        QUARTERLY_FOR_MONTHLY = 'QUARTERLY_FOR_MONTHLY',
        TOTALLY_FREE = 'TOTALLY_FREE',
        CARE_FREE = 'CARE_FREE',
        LABS_FREE = 'LABS_FREE',
        CARE_LABS_FREE = 'CARE_LABS_FREE',
        LABS_AND_ONE_MED_FREE = 'LABS_AND_ONE_MED_FREE',
        ABSOLUTE_20 = 'ABSOLUTE_20',
    }


}

