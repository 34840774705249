/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AnswerItemDto } from './AnswerItemDto';
import type { IneligibilityReason } from './IneligibilityReason';
import type { QuestionnaireRef } from './QuestionnaireRef';
import type { TreatmentPlanPurchaseItemSuggestion } from './TreatmentPlanPurchaseItemSuggestion';

export type SuggestedTreatmentPlan = {
    eligibility?: SuggestedTreatmentPlan.eligibility;
    ineligibilityReasons?: Array<IneligibilityReason>;
    dataNeededReasons?: Array<'STATE' | 'NAME' | 'DATE_OF_BIRTH' | 'ANONYMOUS' | 'A1C_MISSING' | 'A1C_ORDERED_NO_RESULT' | 'SEX' | 'PREGNANT_OR_BREASTFEEDING' | 'MEDICAL_CONDITIONS' | 'MEDICATIONS' | 'MEDICAL_SYMPTOMS' | 'BLOOD_PRESSURE_MISSING' | 'MEDICARE' | 'CARE_TEAM_CALL' | 'ETHNICITIES' | 'BMI'>;
    purchasableItems?: Array<TreatmentPlanPurchaseItemSuggestion>;
    questionnaireRef?: QuestionnaireRef;
    alternativeStep?: QuestionnaireRef;
    answers?: Array<AnswerItemDto>;
    eligible?: boolean;
    ineligible?: boolean;
};

export namespace SuggestedTreatmentPlan {

    export enum eligibility {
        ELIGIBLE = 'ELIGIBLE',
        INELIGIBLE = 'INELIGIBLE',
        DATA_NEEDED = 'DATA_NEEDED',
    }


}

