/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddSubscriptionItemRequest } from '../models/AddSubscriptionItemRequest';
import type { ApplyCouponRequest } from '../models/ApplyCouponRequest';
import type { ChangeSubscriptionRenewalIntervalRequest } from '../models/ChangeSubscriptionRenewalIntervalRequest';
import type { RemoveSubscriptionItemRequest } from '../models/RemoveSubscriptionItemRequest';
import type { ReserveCouponRequest } from '../models/ReserveCouponRequest';
import type { SuccessAddSubscriptionItemResponse } from '../models/SuccessAddSubscriptionItemResponse';
import type { SuccessCollectionSubscriptionDetailsResponse } from '../models/SuccessCollectionSubscriptionDetailsResponse';
import type { SuccessCreateSubscriptionResponse } from '../models/SuccessCreateSubscriptionResponse';
import type { SuccessGetReferralCodeOwnerDetailsResponse } from '../models/SuccessGetReferralCodeOwnerDetailsResponse';
import type { SuccessGetReferralDetailsResponse } from '../models/SuccessGetReferralDetailsResponse';
import type { SuccessGetSubscriptionPricesResponse } from '../models/SuccessGetSubscriptionPricesResponse';
import type { SuccessGetSubscriptionStateResponse } from '../models/SuccessGetSubscriptionStateResponse';
import type { SuccessGetSuggestedSubscriptionStepResponse } from '../models/SuccessGetSuggestedSubscriptionStepResponse';
import type { SuccessSubscriptionDetailsResponse } from '../models/SuccessSubscriptionDetailsResponse';
import type { SuccessVoid } from '../models/SuccessVoid';
import type { UpdateSubscriptionItemRequest } from '../models/UpdateSubscriptionItemRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SubscriptionControllerService {

    /**
     * Changes a subscription's renewal interval (= the period used to deliver services)
     * @param subscriptionId The id of subscription
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static changeRenewalInterval(
        subscriptionId: string,
        requestBody: ChangeSubscriptionRenewalIntervalRequest,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/subscriptions/{subscriptionId}/renewal-interval',
            path: {
                'subscriptionId': subscriptionId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Subscription could not be created. User is ineligible to create subscription, or invalid renewal interval provided`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `Subscription could not be provided. Not found or user does not have access`,
                500: `An unexpected error has occurred.`,
            },
        });
    }

    /**
     * Returns a collection of all subscriptions for a user.
     * @returns SuccessCollectionSubscriptionDetailsResponse OK
     * @throws ApiError
     */
    public static listSubscriptions(): CancelablePromise<SuccessCollectionSubscriptionDetailsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/subscriptions',
            errors: {
                400: `Bad Request`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
                500: `An unexpected error has occurred.`,
            },
        });
    }

    /**
     * Creates initial draft of user subscription
     * Create initial draft of user subscription basing on current treatment plan suggestion
     * @param useTreatmentPlan Indicates whether the subscription should be pre-populated with items from the treatment plan.
     * @param renewalInterval Renewal interval of new created subscription draft
     * @param paymentInterval
     * @returns SuccessCreateSubscriptionResponse The subscription was created successfully; the subscription ID is returned in the response body.
     * @throws ApiError
     */
    public static createSubscription(
        useTreatmentPlan: string = 'true',
        renewalInterval?: string,
        paymentInterval?: string,
    ): CancelablePromise<SuccessCreateSubscriptionResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/subscriptions',
            query: {
                'useTreatmentPlan': useTreatmentPlan,
                'renewalInterval': renewalInterval,
                'paymentInterval': paymentInterval,
            },
            errors: {
                400: `Subscription could not be created. User is ineligible to create subscription`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
                500: `An unexpected error has occurred.`,
            },
        });
    }

    /**
     * Add item to subscription
     * Adds new item to subscription
     * @param subscriptionId The id of subscription
     * @param requestBody
     * @returns SuccessAddSubscriptionItemResponse The item has been added successfully to subscription
     * @throws ApiError
     */
    public static addSubscriptionItem(
        subscriptionId: string,
        requestBody: AddSubscriptionItemRequest,
    ): CancelablePromise<SuccessAddSubscriptionItemResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/subscriptions/{subscriptionId}/items',
            path: {
                'subscriptionId': subscriptionId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `Subscription or item for sku could not be found.`,
                500: `An unexpected error has occurred.`,
            },
        });
    }

    /**
     * Delete item from subscription
     * Deletes item with given id from subscription
     * @param subscriptionId The id of subscription
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static removeSubscriptionItem(
        subscriptionId: string,
        requestBody: RemoveSubscriptionItemRequest,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/subscriptions/{subscriptionId}/items',
            path: {
                'subscriptionId': subscriptionId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Item could not be removed from subscription.`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `Subscription or item could not be found.`,
                500: `An unexpected error has occurred.`,
            },
        });
    }

    /**
     * Applies a coupon (voucher) to a subscription.
     * This endpoint only attaches the coupon to the subscription, and does not perform any immediate action. Once the subscription is activated (bought), an attached coupon will be considered.
     * @param subscriptionId The id of the subscription.
     * @param requestBody
     * @returns SuccessSubscriptionDetailsResponse The coupon has been applied to the subscription.
     * @throws ApiError
     */
    public static applyCoupon(
        subscriptionId: string,
        requestBody: ApplyCouponRequest,
    ): CancelablePromise<SuccessSubscriptionDetailsResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/subscriptions/{subscriptionId}/coupons',
            path: {
                'subscriptionId': subscriptionId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The subscription or the coupon were not found.`,
                500: `An unexpected error has occurred.`,
            },
        });
    }

    /**
     * Removes a coupon from a subscription.
     * @param subscriptionId
     * @returns void
     * @throws ApiError
     */
    public static removeCoupon(
        subscriptionId: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/subscriptions/{subscriptionId}/coupons',
            path: {
                'subscriptionId': subscriptionId,
            },
            errors: {
                400: `Bad Request`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The coupon was not found.`,
                500: `An unexpected error has occurred.`,
            },
        });
    }

    /**
     * buy subscription
     * Start buy subscription process. Reserve money, move subscription to review status, create consultation in provider
     * @param subscriptionId The id of subscription
     * @returns SuccessVoid The subscription buy process has been started
     * @throws ApiError
     */
    public static buySubscription(
        subscriptionId: string,
    ): CancelablePromise<SuccessVoid> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/subscriptions/{subscriptionId}/buy',
            path: {
                'subscriptionId': subscriptionId,
            },
            errors: {
                400: `There was an issue initiating the subscription, e.g., because of an issue with the payment method.`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `Subscription not be found.`,
                500: `An unexpected error has occurred.`,
            },
        });
    }

    /**
     * Reserves a coupon for the user.
     * This endpoint reserves a coupon for the user, without actually using it. If the user creates a subscription later on, the coupon will be applied to that subscription.
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static reserveCoupon(
        requestBody: ReserveCouponRequest,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/subscriptions/coupons',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The coupon was not found.`,
                500: `An unexpected error has occurred.`,
            },
        });
    }

    /**
     * Updates a subscription item with provided data
     * @param subscriptionId The id of subscription
     * @param itemId The id of item in subscription to update
     * @param requestBody
     * @returns SuccessSubscriptionDetailsResponse The subscription details.
     * @throws ApiError
     */
    public static updateSubscriptionItemByUser(
        subscriptionId: string,
        itemId: string,
        requestBody: UpdateSubscriptionItemRequest,
    ): CancelablePromise<SuccessSubscriptionDetailsResponse> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v1/subscriptions/{subscriptionId}/items/{itemId}',
            path: {
                'subscriptionId': subscriptionId,
                'itemId': itemId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `Subscription or item could not be found.`,
                500: `An unexpected error has occurred.`,
            },
        });
    }

    /**
     * Provide user subscription
     * Provides user subscription details for given id
     * @param subscriptionId The id of subscription
     * @returns SuccessSubscriptionDetailsResponse The subscription details.
     * @throws ApiError
     */
    public static subscriptionDetails(
        subscriptionId: string,
    ): CancelablePromise<SuccessSubscriptionDetailsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/subscriptions/{subscriptionId}',
            path: {
                'subscriptionId': subscriptionId,
            },
            errors: {
                400: `Bad Request`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `Subscription could not be provided. Not found or user does not have access`,
                500: `An unexpected error has occurred.`,
            },
        });
    }

    /**
     * Provides subscription prices
     * Subscription prices per renewalInterval, current renewalInterval and currency. Also provides savings per renewalInterval according to currently chosen, and quarterly to monthly savings.
     * @param subscriptionId The id of subscription
     * @returns SuccessGetSubscriptionPricesResponse Prices were calculated successfully.
     * @throws ApiError
     */
    public static getSubscriptionPricing(
        subscriptionId: string,
    ): CancelablePromise<SuccessGetSubscriptionPricesResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/subscriptions/{subscriptionId}/prices',
            path: {
                'subscriptionId': subscriptionId,
            },
            errors: {
                400: `User is ineligible to have a subscription.`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `Subscription could not found or user doesn't have access for it.`,
                500: `An unexpected error has occurred.`,
            },
        });
    }

    /**
     * Get information about current subscription.
     * Returns data about latest subscription.
     * @returns SuccessGetSubscriptionStateResponse State of the subscription was returned succesfully.
     * @throws ApiError
     */
    public static getCurrentSubscriptionState(): CancelablePromise<SuccessGetSubscriptionStateResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/subscriptions/users/me/current',
            errors: {
                400: `Bad Request`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
                500: `An unexpected error has occurred.`,
            },
        });
    }

    /**
     * Suggests next step of subscription configuration process.
     * Basing on current step of configuration and required data, suggests next step of configuration.
     * @param currentStep Currently handled step, if not given, first step of configuration process will be suggested
     * @returns SuccessGetSuggestedSubscriptionStepResponse Next step suggestion succeeded.
     * @throws ApiError
     */
    public static suggestSubscriptionNextStep(
        currentStep?: 'LAB_TEST_CUSTOMIZE' | 'SUBSCRIPTION_PERIOD_CUSTOMIZE' | 'SHIPMENT_ADDRESS_DATA' | 'IDENTITY_VERIFICATION_INFO' | 'IDENTITY_VERIFICATION_FACE_PHOTO' | 'IDENTITY_VERIFICATION_ID_PHOTO' | 'ORDER_REVIEW_WITH_PAYMENT_DATA',
    ): CancelablePromise<SuccessGetSuggestedSubscriptionStepResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/subscriptions/steps/suggest',
            query: {
                'currentStep': currentStep,
            },
            errors: {
                400: `Bad Request`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
                500: `An unexpected error has occurred.`,
            },
        });
    }

    /**
     * Get information about users referrals.
     * Returns data about user referral code and number of successfull referrals.
     * @returns SuccessGetReferralDetailsResponse Referral details fetched succesfully.
     * @throws ApiError
     */
    public static getReferralDetails(): CancelablePromise<SuccessGetReferralDetailsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/subscriptions/referral',
            errors: {
                400: `Bad Request`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
                500: `An unexpected error has occurred.`,
            },
        });
    }

    /**
     * Get information about referral code owner.
     * Returns data about the referral code owner, like name.
     * @param referralCode
     * @returns SuccessGetReferralCodeOwnerDetailsResponse Referral code owner data fetched succesfully.
     * @throws ApiError
     */
    public static getReferralCodeOwnerDetails(
        referralCode: string,
    ): CancelablePromise<SuccessGetReferralCodeOwnerDetailsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/subscriptions/referral/code/{referralCode}',
            path: {
                'referralCode': referralCode,
            },
            errors: {
                400: `Bad Request`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `Given referral code not found.`,
                500: `An unexpected error has occurred.`,
            },
        });
    }

}
