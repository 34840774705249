/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Instructions for further subscription customization.
 */
export type GetSuggestedSubscriptionStepResponse = {
    /**
     * Next step of configuration.
     */
    nextStep: GetSuggestedSubscriptionStepResponse.nextStep;
    /**
     * Information whether input data for suggested step is available to fetch.
     */
    nextStepInputDataAvailable: boolean;
    /**
     * True when there are no more steps, data is collected and subscription configuration is complete.
     */
    configurationCompleted: boolean;
};

export namespace GetSuggestedSubscriptionStepResponse {

    /**
     * Next step of configuration.
     */
    export enum nextStep {
        LAB_TEST_CUSTOMIZE = 'LAB_TEST_CUSTOMIZE',
        SUBSCRIPTION_PERIOD_CUSTOMIZE = 'SUBSCRIPTION_PERIOD_CUSTOMIZE',
        SHIPMENT_ADDRESS_DATA = 'SHIPMENT_ADDRESS_DATA',
        IDENTITY_VERIFICATION_INFO = 'IDENTITY_VERIFICATION_INFO',
        IDENTITY_VERIFICATION_FACE_PHOTO = 'IDENTITY_VERIFICATION_FACE_PHOTO',
        IDENTITY_VERIFICATION_ID_PHOTO = 'IDENTITY_VERIFICATION_ID_PHOTO',
        ORDER_REVIEW_WITH_PAYMENT_DATA = 'ORDER_REVIEW_WITH_PAYMENT_DATA',
    }


}

