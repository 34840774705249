/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { MemberDataItem } from './MemberDataItem';

export type EligibilityMatchesResponse = {
    /**
     * The data of the user for whom potential matches are found
     */
    referenceData?: MemberDataItem;
    /**
     * A list of potential matches for the reference user
     */
    partialMatches?: Array<MemberDataItem>;
    /**
     * Last eligibility status
     */
    eligibilityStatus?: EligibilityMatchesResponse.eligibilityStatus;
};

export namespace EligibilityMatchesResponse {

    /**
     * Last eligibility status
     */
    export enum eligibilityStatus {
        ELIGIBLE = 'ELIGIBLE',
        INELIGIBLE = 'INELIGIBLE',
        INFO_UNAVAILABLE = 'INFO_UNAVAILABLE',
        SKIPPED = 'SKIPPED',
        EXTERNAL_PROVIDER_ERROR = 'EXTERNAL_PROVIDER_ERROR',
    }


}

