/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Information that needs to be provided in order to place a new lab order.
 */
export type PlaceLabOrderRequest = {
    /**
     * The user ID
     */
    userId: string;
    /**
     * The trigger date for the lab order. Defaults to immediate.
     */
    triggerAt?: string;
    /**
     * The lab order provider type
     */
    labOrderProviderType?: PlaceLabOrderRequest.labOrderProviderType;
};

export namespace PlaceLabOrderRequest {

    /**
     * The lab order provider type
     */
    export enum labOrderProviderType {
        TRUEPILL = 'TRUEPILL',
        BIO_REFERENCE_LAB = 'BIO_REFERENCE_LAB',
        PATIENT = 'PATIENT',
        ZENDESK = 'ZENDESK',
        GETLABS = 'GETLABS',
        QUEST = 'QUEST',
        LABCORP = 'LABCORP',
        KWIK_TRIP_CENTER_FOR_HEALTH = 'KWIK_TRIP_CENTER_FOR_HEALTH',
        EVERLYWELL = 'EVERLYWELL',
        TASSO = 'TASSO',
    }


}

