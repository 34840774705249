/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { FulfillmentSlot } from './FulfillmentSlot';

export type MealPlanAdminResponse = {
    /**
     * Total number of recipes in a meal plan
     */
    recipes: number;
    /**
     * Number of rated (completed) recipes in a meal plan
     */
    ratedRecipes: number;
    /**
     * This value is non-null in case user ordered a meal plan to be delivered by RxDiet
     */
    orderStatus?: MealPlanAdminResponse.orderStatus;
    /**
     * The fulfillment time slot
     */
    fulfillmentSlot?: FulfillmentSlot;
    /**
     * When was the meal plan generated
     */
    createdTime: string;
    /**
     * When was the meal plan marked as visible in the app (datetime in UTC). In case user uses a shopping list feature, this is the datetime when they marked the shopping as finished in the app
     */
    selectedTime?: string;
    /**
     * When was the meal plan ordered in case user ordered delivery from RxDiet (datetime in UTC)
     */
    orderingTime?: string;
};

export namespace MealPlanAdminResponse {

    /**
     * This value is non-null in case user ordered a meal plan to be delivered by RxDiet
     */
    export enum orderStatus {
        ACCEPTED = 'ACCEPTED',
        CANCELED = 'CANCELED',
        CARRIER_ISSUE = 'CARRIER_ISSUE',
        FULFILLED = 'FULFILLED',
        FULFILLMENT_CONFIRMED = 'FULFILLMENT_CONFIRMED',
        MEMBER_NOT_AVAILABLE = 'MEMBER_NOT_AVAILABLE',
        NOT_ON_TIME = 'NOT_ON_TIME',
        OUT_FOR_DELIVERY = 'OUT_FOR_DELIVERY',
        PLACED = 'PLACED',
        READY_FOR_PICK_UP = 'READY_FOR_PICK_UP',
        STOLEN = 'STOLEN',
        WRONG_ADDRESS = 'WRONG_ADDRESS',
    }


}

