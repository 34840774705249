/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Represents the treatment plan suggestion purchase item Contains all the data related to the suggested treatment plan purchase items like id, type, price, sku and price list version
 */
export type TreatmentPlanPurchaseItem = {
    /**
     * Unique UUID of item
     */
    id: string;
    /**
     * Type of item , one of the : MedicationSuggestion | LabTestSuggestion | SubscriptionPlanSuggestion
     */
    type: TreatmentPlanPurchaseItem.type;
    /**
     * Sku code of medication or labtest or subscription plan
     */
    sku: string;
    /**
     * Additional data related with suggested item
     */
    metadata: Record<string, any>;
};

export namespace TreatmentPlanPurchaseItem {

    /**
     * Type of item , one of the : MedicationSuggestion | LabTestSuggestion | SubscriptionPlanSuggestion
     */
    export enum type {
        SUBSCRIPTION_PLAN_SUGGESTION = 'SubscriptionPlanSuggestion',
        LAB_TEST_SUGGESTION = 'LabTestSuggestion',
        MEDICATION_SUGGESTION = 'MedicationSuggestion',
        PRELIMINARY_MEDICATION_SUGGESTION = 'PreliminaryMedicationSuggestion',
        PRESCRIBED_MEDICATION = 'PrescribedMedication',
        DOCUMENTED_MEDICATION = 'DocumentedMedication',
        PRESCRIBED_SUPPLY = 'PrescribedSupply',
        OVER_THE_COUNTER_GLUCOMETER = 'OverTheCounterGlucometer',
        OVER_THE_COUNTER_LAB_TEST = 'OverTheCounterLabTest',
        OVER_THE_COUNTER_MICROLET_COLOR_LANCET_ITEM = 'OverTheCounterMicroletColorLancetItem',
        OVER_THE_COUNTER_STRIP_ITEM = 'OverTheCounterStripItem',
        SUBSCRIBED_DEVICE = 'SubscribedDevice',
    }


}

