/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CarePlanActionRequest } from './CarePlanActionRequest';

export type StopDiagnosisCarePlanActionRequest = (CarePlanActionRequest & {
    /**
     * diagnosis code
     */
    diagnosisCode?: string;
    /**
     * diagnosis name
     */
    name?: string;
    /**
     * diagnosis start month
     */
    month?: StopDiagnosisCarePlanActionRequest.month;
    /**
     * diagnosis start year
     */
    year?: {
        value?: number;
        leap?: boolean;
    };
    /**
     * Stop cause
     */
    stopCause?: StopDiagnosisCarePlanActionRequest.stopCause;
} & {
    /**
     * Stop cause
     */
    stopCause: StopDiagnosisCarePlanActionRequest.stopCause;
});

export namespace StopDiagnosisCarePlanActionRequest {

    /**
     * diagnosis start month
     */
    export enum month {
        JANUARY = 'JANUARY',
        FEBRUARY = 'FEBRUARY',
        MARCH = 'MARCH',
        APRIL = 'APRIL',
        MAY = 'MAY',
        JUNE = 'JUNE',
        JULY = 'JULY',
        AUGUST = 'AUGUST',
        SEPTEMBER = 'SEPTEMBER',
        OCTOBER = 'OCTOBER',
        NOVEMBER = 'NOVEMBER',
        DECEMBER = 'DECEMBER',
    }

    /**
     * Stop cause
     */
    export enum stopCause {
        CONDITION_WAS_ENTERED_IN_ERROR = 'CONDITION_WAS_ENTERED_IN_ERROR',
        CONDITION_WAS_RESOLVED = 'CONDITION_WAS_RESOLVED',
        CONDITION_WAS_REPLACED_WITH_ANOTHER_ONE = 'CONDITION_WAS_REPLACED_WITH_ANOTHER_ONE',
        CONDITION_WAS_DIAGNOSED_INCORRECTLY = 'CONDITION_WAS_DIAGNOSED_INCORRECTLY',
    }


}

