/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An item to be included in the shipment.
 */
export type ShippableItem = {
    /**
     * Identifies the subscription to which this item is associated.
     */
    subscriptionId: string;
    /**
     * Identifies the purchase item to which this item is associated.
     */
    purchaseItemId: string;
    /**
     * The type of the item to be shipped.
     */
    itemType: ShippableItem.itemType;
    /**
     * A SKU to identify this item within the shipment.
     */
    sku: string;
    /**
     * The quantity to be shipped.
     */
    quantity: number;
};

export namespace ShippableItem {

    /**
     * The type of the item to be shipped.
     */
    export enum itemType {
        MEDICATION = 'MEDICATION',
        LAB_TEST_KIT = 'LAB_TEST_KIT',
        TEST_STRIP = 'TEST_STRIP',
        GLUCOMETER = 'GLUCOMETER',
        LANCET = 'LANCET',
        COMPLEMENT = 'COMPLEMENT',
        WELCOME_KIT = 'WELCOME_KIT',
        BLOOD_PRESSURE_MONITOR = 'BLOOD_PRESSURE_MONITOR',
        CONNECTED_WEIGHT_SCALE = 'CONNECTED_WEIGHT_SCALE',
    }


}

