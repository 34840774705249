/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Contains information about purchasable items.
 */
export type SubscriptionPurchasableItemResponse = {
    /**
     * The SKU of a product that can be added.
     */
    sku: string;
    /**
     * The human-readable name of the purchase item.
     */
    displayName: string;
    /**
     * The external item type of the item.
     */
    externalItemType: SubscriptionPurchasableItemResponse.externalItemType;
    /**
     * The price for this item if added, considering the current subscription renewal interval.
     */
    price: number;
    /**
     * The currency of the item price
     */
    currency: string;
};

export namespace SubscriptionPurchasableItemResponse {

    /**
     * The external item type of the item.
     */
    export enum externalItemType {
        SUBSCRIPTION_PLAN_SUGGESTION = 'SubscriptionPlanSuggestion',
        LAB_TEST_SUGGESTION = 'LabTestSuggestion',
        MEDICATION_SUGGESTION = 'MedicationSuggestion',
        PRELIMINARY_MEDICATION_SUGGESTION = 'PreliminaryMedicationSuggestion',
        PRESCRIBED_MEDICATION = 'PrescribedMedication',
        DOCUMENTED_MEDICATION = 'DocumentedMedication',
        PRESCRIBED_SUPPLY = 'PrescribedSupply',
        OVER_THE_COUNTER_GLUCOMETER = 'OverTheCounterGlucometer',
        OVER_THE_COUNTER_LAB_TEST = 'OverTheCounterLabTest',
        OVER_THE_COUNTER_MICROLET_COLOR_LANCET_ITEM = 'OverTheCounterMicroletColorLancetItem',
        OVER_THE_COUNTER_STRIP_ITEM = 'OverTheCounterStripItem',
        SUBSCRIBED_DEVICE = 'SubscribedDevice',
    }


}

