/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { RampUpDosageSuggestion } from './RampUpDosageSuggestion';

export type LinearRampUpDosageSuggestion = (RampUpDosageSuggestion & {
    periodIncrement?: LinearRampUpDosageSuggestion.periodIncrement;
    startingDailyQuantity?: number;
});

export namespace LinearRampUpDosageSuggestion {

    export enum periodIncrement {
        NANOS = 'NANOS',
        MICROS = 'MICROS',
        MILLIS = 'MILLIS',
        SECONDS = 'SECONDS',
        MINUTES = 'MINUTES',
        HOURS = 'HOURS',
        HALF_DAYS = 'HALF_DAYS',
        DAYS = 'DAYS',
        WEEKS = 'WEEKS',
        MONTHS = 'MONTHS',
        YEARS = 'YEARS',
        DECADES = 'DECADES',
        CENTURIES = 'CENTURIES',
        MILLENNIA = 'MILLENNIA',
        ERAS = 'ERAS',
        FOREVER = 'FOREVER',
    }


}

