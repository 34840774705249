/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type MedicationAbatement = {
    timestamp?: string;
    reason?: MedicationAbatement.reason;
};

export namespace MedicationAbatement {

    export enum reason {
        UNKNOWN = 'UNKNOWN',
        NO_TOLERATE_SIDE_EFFECTS = 'NO_TOLERATE_SIDE_EFFECTS',
        INEFFECTIVE = 'INEFFECTIVE',
        INAPPROPRIATE = 'INAPPROPRIATE',
        DOCTOR_STOPPED_PATIENT_DISLIKES = 'DOCTOR_STOPPED_PATIENT_DISLIKES',
        DOCTOR_STOPPED_OTHER = 'DOCTOR_STOPPED_OTHER',
        TREATMENT_STOPPED_WITH_ALTERNATIVE = 'TREATMENT_STOPPED_WITH_ALTERNATIVE',
        TREATMENT_STILL_NEEDED = 'TREATMENT_STILL_NEEDED',
    }


}

